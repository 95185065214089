<template lang="pug">
  div
    #friend-request(@click="sendFriendRequest(targetUserId)" v-if="isFriendRequestLinkShow")
      i.far.fa-envelope &nbsp; 友達リクエスト
    #friend-request(@click="callRouterPush('/friend/friendRequest')" v-if="isFriendRequestReceiveLinkShow")
      i.far.fa-envelope.received &nbsp; 友達リクエストが<br>届いています
    #friend-request-status(v-if="isFriendRequestStatusShow") {{friendRequestStatusMessage}}

</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      targetUserId:'',
      //isFriendRequestLinkShow:false,
      //debug用にtrue
      isFriendRequestLinkShow:true,
      isFriendRequestStatusShow:false,
      isFriendRequestReceiveLinkShow:false,
      isClimbCountShow:false,
      isCheckinHistoryShow:false,
      modal: false,
      //友達リクエストの状況
      ADDED:'ADDED',
      APPROVAL_PENDING:'APPROVAL_PENDING',
      REQUEST_ARRIVED:'REQUEST_ARRIVED',
      friendRequestStatusMessage:''
    }
  },
  created(){
    this.targetUserId = this.$parent.userId

    //ログイン中の場合は友達リクエストの状況を取得する
    if(this.$user.isLogin){
      //ログインユーザー自身のデータを表示している場合は、友達リクエスト部分は非表示にする
      if(this.targetUserId == this.$user.userId){
        this.isFriendRequestLinkShow = false
        this.isFriendRequestStatusShow = false
        return
      }
      //ログイン中の場合
      this.getFriedRequestStatus()
    }else{
      //未ログインの場合
      this.isFriendRequestLinkShow = true
    }
  },
  // メソッド
  methods: {
    //------------------------
    //友達リクエストの状況取得
    //------------------------
    async getFriedRequestStatus(){
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-friend-request/get-status'
      await axios
        .get(jsonUrl,{
          params: {
            //取得対象のユーザーID
            'targetUserId': this.targetUserId,
          },         
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {
          let result = response.data
          this.setFriendRequestLabelAndLinkByStatus(result.message,false)
        })
        .catch(err => {
          alert('システムエラーが発生しました')
        }) 
    },
    callRouterPush(path){
      //モーダルの親画面が友達リクエスト画面の場合はモーダルを閉じる
      if(this.$route.path.match('/friend/friendRequest')){
        this.$parent.closeModal()
      }
      this.$router.push(path)
    },
    //------------------------
    //友達リクエスト送信API実行
    //------------------------
    async sendFriendRequest(targetUserId){

      //未ログインの場合はログイン画面に遷移
      if(!this.$user.isLogin){
        this.$router.push({ name: 'login', query: { url:this.$route.fullPath }})
        return
      }

      //確認メッセージ
      const message = this.$parent.userName + 'に友達リクエストを送信しますか？'
      const answer1 = '送信する'
      const answer2 = '送信しない'
      if(!await this.asyncDialogAnswer(message,answer1,answer2)){
        return
      }

      //リンクを非表示にする(連打防止)
      this.isFriendRequestLinkShow = false
      this.friendRequestStatusMessage = '友達リクエスト送信処理中…'
      this.isFriendRequestStatusShow = true

      //友達リクエストAPI送信実行
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-friend-request/send-request'
      let result = ""
      const params = {
        targetUserId : targetUserId
      }
      await axios.patch(jsonUrl, params, {
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.apiMessage = res.data.message
      })
      .catch(err => {
        //TODO:システムエラー
        alert('友達リクエストの送信でエラーが発生しました。')
        this.friendRequestStatusMessage = ''
        this.isFriendRequestStatusShow = false
        this.isFriendRequestLinkShow = true
        return
      })

      if(this.apiMessage != ""){   
        this.setFriendRequestLabelAndLinkByStatus(this.apiMessage,true)
      }else{
        await this.asyncDialogComp( this.$parent.userName + 'に友達リクエストを送信しました。承認されるまでしばらくお待ちください。')
        this.friendRequestStatusMessage = '友達リクエスト承認待ち'
        this.isFriendRequestLinkShow = false
        this.isFriendRequestStatusShow = true
      }

    },
    //--------------------------------------------------
    //友達リクエストの状況に合ったラベル、リンクをセットする
    //--------------------------------------------------
    async setFriendRequestLabelAndLinkByStatus(status,isMessageShow){

      let message = ''
      //友達リクエストの状況
      if(status != ''){
        this.isFriendRequestLinkShow = false
        this.isFriendRequestStatusShow = true

        if(status == this.ADDED){
          this.friendRequestStatusMessage = '友達登録済み'
          message = '既に友達登録しているユーザーです'
        }else if(status == this.APPROVAL_PENDING){
          this.friendRequestStatusMessage = '友達リクエスト承認待ち'
          message = '友達リクエスト承認待ちです'
        }else if(status == this.REQUEST_ARRIVED){
          this.friendRequestStatusMessage = ''
          this.isFriendRequestStatusShow = false
          this.isFriendRequestReceiveLinkShow = true
          message = 'このユーザーから友達リクエストが到着しています。'
        }

        if(isMessageShow){
          await this.asyncDialogComp(message)
        }

      }else{
        this.isFriendRequestLinkShow = true
      }
    },
    //------------------------
    //確認ダイアログ
    //------------------------
    asyncDialogAnswer(message,answer1,answer2) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxConfirm(message, {
          title: '確認',
          okVariant: 'success',
          okTitle: answer1,
          cancelTitle: answer2,
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          resolve(value)
        })
      })
    },
    //------------------------
    //実行結果ダイアログ
    //------------------------
    asyncDialogComp(message) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(message, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
      })
    },
  },
  // コンポーネント
  components: {
  }
}
</script>

<style lang="stylus" scoped>
div
  width 100%
  #friend-request,#friend-request-status
    text-align right
    color #7dbae5
    font-size 0.7rem
    .far
      font-weight 700
  #friend-request
    cursor pointer
    i
      text-decoration underline
  .received
    font-size 0.8rem
    color #df5f6a
    font-weight 600
</style>