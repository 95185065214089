var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isFriendRequestLinkShow
      ? _c(
          "div",
          {
            attrs: { id: "friend-request" },
            on: {
              click: function($event) {
                return _vm.sendFriendRequest(_vm.targetUserId)
              }
            }
          },
          [
            _c("i", { staticClass: "far fa-envelope" }, [
              _vm._v(" 友達リクエスト")
            ])
          ]
        )
      : _vm._e(),
    _vm.isFriendRequestReceiveLinkShow
      ? _c(
          "div",
          {
            attrs: { id: "friend-request" },
            on: {
              click: function($event) {
                return _vm.callRouterPush("/friend/friendRequest")
              }
            }
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm.isFriendRequestStatusShow
      ? _c("div", { attrs: { id: "friend-request-status" } }, [
          _vm._v(_vm._s(_vm.friendRequestStatusMessage))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", { staticClass: "far fa-envelope received" }, [
      _vm._v(" 友達リクエストが"),
      _c("br"),
      _vm._v("届いています")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }